import { render, staticRenderFns } from "./GeneralConditionsTerm.vue?vue&type=template&id=4c70e194&scoped=true&xmlns=http%3A%2F%2Fwww.w3.org%2F1999%2Fhtml&"
import script from "./GeneralConditionsTerm.vue?vue&type=script&lang=js&"
export * from "./GeneralConditionsTerm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c70e194",
  null
  
)

export default component.exports