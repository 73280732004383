<template xmlns="http://www.w3.org/1999/html">
  <b-row>
    <b-col>
      <b-row>
        <b-col>
          <b-row class="p-1">
            <b-col cols="12">
              <h1>Condições Gerais</h1>
            </b-col>
            <b-col cols="12">
              <h4>Inclusão de certificado digital</h4>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card>
                <div class="p-2">
                  <div>
                    <b-embed :src="termLink" allowfullscreen
                             type="iframe"></b-embed>
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {BCard, BCol, BEmbed, BRow} from "bootstrap-vue";

export default {
  name: "GeneralConditionsTerm",
  components: {
    BRow, BCol, BCard, BEmbed
  },
  data() {
    return {
      termLink: 'https://policies-creditcorp-public.s3.sa-east-1.amazonaws.com/Condic%CC%A7o%CC%83es+Gerais+para+Permissa%CC%83o+de+Utilizac%CC%A7a%CC%83o+de+Certificado+Digital+Para+Acesso+a%CC%80s+Informac%CC%A7o%CC%83es+Fiscais+Creditcorp.pdf'
    }
  }
}
</script>

<style scoped>
</style>
